<template>
  <div>

    <v-toolbar flat>
      <v-toolbar-title>{{$t('MySubscriptions')}}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <!-- Start Filters -->
    <!-- <div>
      <v-row>
        <v-col :md="3">
          <div>
            <label>{{ $t("Name") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="name_ar"
              hide-details
              dense
              outlined
              v-model="filters.name"
            ></v-text-field>
          </div>
        </v-col>

        <v-col :md="3">
          <div>
            <label>{{ $t("Status") }}</label>
            <v-select
              :clearable="false"
              class="d-block my-2"
              name="status"
              hide-details
              dense
              outlined
              :multiple="false"
              item-text="text"
              item-value="value"
              v-model="filters.status"
              :items="filtersItems"
            ></v-select>
          </div>
        </v-col>

        <v-col :md="1" class="mt-6">
          <v-btn
            color="third"
            hide-details
            @click="getDataFromApi"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Filter") }}
          </v-btn>
        </v-col>
        <v-col :md="1" class="mt-6">
          <v-btn
            color="danger"
            hide-details
            @click="clearFilters"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Clear") }}
          </v-btn>
        </v-col>
      </v-row>
    </div> -->
    <!-- End Filters -->

    <!-- Start DataTable -->
    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      :items-per-page="table.itemsPerPage"
      :page.sync="table.page"
      hide-default-footer
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>


      <template v-slot:item.actions="{ item }">

          <el-button v-if="item.subscribe_is_ended.is_ended== 1" size="small" @click="stop(item)" type="danger">
            
          <v-icon
          class="my-0 mr-2 ml-2"
          dense
          style="color: #fff !important; cursor: pointer; font-size: 18px"
          >mdi-block-helper</v-icon
        >

            إيقاف الاشتراك
            
          </el-button>

            <el-button v-else-if="item.subscribe_is_ended.is_ended== 0" size="small" @click="start(item)" type="success">
            
          <v-icon
          class="my-0 mr-2 ml-2"
          dense
          style="color: #fff !important; cursor: pointer; font-size: 18px"
          >mdi-check-circle</v-icon
        >

            استئناف الاشتراك
            
          </el-button>

        <!-- <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="goToDetails(item.id)"
          color="success"
          >mdi-eye</v-icon
        > -->

      </template>
    </v-data-table>
    <!-- End DataTable -->

    <!-- Start Pagination -->
    <div class="text-center pt-2">
      <v-pagination
        v-if="table.length"
        total-visible="5"
        v-model="table.page"
        :length="table.length"
        @input="changePage(table.page)"
      ></v-pagination>
    </div>

    <!-- End Pagination -->


       <!-- Start Dialog Stop -->
    <v-dialog v-model="dialogStop" width="50rem">
      <v-card>
        <v-toolbar color="danger" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("StopSubscribe") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row class="mt-5">
              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.start_date`),
                  }"
                >
                  <label>{{ $t("StartDate") }}</label>

                  <el-date-picker
                    style="width: 100%"
                    v-model="stopForm.start_date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('StartDate')"
                    class="d-block my-2"
                    name="start_date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.start_date`)"
                >
                  {{ errors.first(`addEditValidation.start_date`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="4">
                <label>{{ $t("EndDate") }}</label>
                <el-date-picker
                  style="width: 100%"
                  v-model="stopForm.end_date"
                  type="date"
                  class="d-block my-2"
                  name="end_date"
                  hide-details
                  dense
                  outlined
                >
                </el-date-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogStop = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="dialogStopConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->

     <!-- Start Dialog Start -->
    <v-dialog v-model="dialogStart" width="50rem">
      <v-card>
        <v-toolbar color="success" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("StartSubscribe") }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-container fluid>
            <v-row class="mt-5">
              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.date`),
                  }"
                >
                  <label>{{ $t("Date") }}</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="startForm.date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('Date')"
                    class="d-block my-2"
                    name="date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.date`)"
                >
                  {{ errors.first(`addEditValidation.date`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="4">
                <label>{{ $t("StopStartDate") }}</label>
                <el-date-picker
                  v-if="
                    startItem &&
                    startItem.stop_subscription &&
                    startItem.stop_subscription.start_date &&
                    startItem.stop_subscription.start_date !== null
                  "
                  style="width: 100%"
                  :value="startItem.stop_subscription.start_date"
                  type="date"
                  class="d-block my-2"
                  name="date"
                  hide-details
                  dense
                  readonly
                  outlined
                >
                </el-date-picker>
                <v-else
                  v-if="
                    startItem &&
                    startItem.stop_subscription &&
                    startItem.stop_subscription.start_date == null
                  "
                >
                  <h4 class="mt-5" style="color: red">
                    {{ $t("NoStopStartDate") }}
                  </h4>
                </v-else>
              </v-col>

              <v-col class="py-0" :cols="4">
                <label>{{ $t("StopEndDate") }}</label>
                <el-date-picker
                  v-if="
                    startItem &&
                    startItem.stop_subscription &&
                    startItem.stop_subscription.end_date &&
                    startItem.stop_subscription.end_date !== null
                  "
                  style="width: 100%"
                  :value="startItem.stop_subscription.end_date"
                  type="date"
                  class="d-block my-2"
                  name="date"
                  hide-details
                  dense
                  outlined
                  readonly
                >
                </el-date-picker>
                <v-else
                  v-if="
                    startItem &&
                    startItem.stop_subscription &&
                    startItem.stop_subscription.end_date == null
                  "
                >
                  <h4 class="mt-5" style="color: red">
                    {{ $t("NoStopEndDate") }}
                  </h4>
                </v-else>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogStart = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="dialogStartConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->


  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: "mySubscriptions",
  data() {
    return {
         user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      filtersItems: [
        { text: this.$t("All"), value: null },
        { text: this.$t("Active"), value: 1 },
        { text: this.$t("InActive"), value: 0 },
      ],
      config: {
        tableName: "CustomersSubscriptions",
        crudHeader: "CustomersSubscriptions",
        crudApi: "customers",
        filterWidth: "20rem",
        deleteModalWidth: "30rem",
        modalFormWidth: "50rem",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 10,
        loading: false,
        items: [],
        headers: [
          { text: "#", value: "id", width: "5rem" },
          {
            text: this.$t("PackageName"),
            value: "package_name",
            width: "8rem",
          },
          {
            text: this.$t("StartSubscriptionDate"),
            value: "start_date",
            width: "8rem",
          },
          {
            text: this.$t("EndSubscriptionDate"),
            value: "end_date",
            width: "8rem",
          },
          {
            text: this.$t("StatusSubscription"),
            value: "subscribe_is_ended.is_ended_text",
            width: "8rem",
          },
          {
            text: this.$t("RemindDays"),
            value: "remind_days",
            width: "8rem",
          },
          {
            text: this.$t("PaymentStatus"),
            value: "payment.status",
            width: "8rem",
          },
          {
            text: this.$t("PaymentBank"),
            value: "payment.bank_name",
            width: "8rem",
          },
          {
            text: this.$t("PaymentDate"),
            value: "payment.date",
            width: "8rem",
          },
          {
            text: this.$t("Actions"),
            value: "actions",
            width: "10rem",
          },
        ],
      },
      filters: {
        name: null,
        status: null,
      },
      stopForm: {
        start_date: null,
        end_date: null,
      },
      startForm: {
        date: null,
      },
      stopItem: null,
      startItem: null,
      dialogStop: false,
      dialogStart: false,
    };
  },
  methods: {
    dialogStartConfirm() {
      let sendData = {
        id: this.startItem.id,
        formData: JSON.parse(JSON.stringify(this.startForm)),
      };

      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        if (sendData.formData.date)
          sendData.formData.date = moment(sendData.formData.date).format(
            "YYYY-MM-DD"
          );

        this.$store
          .dispatch(`customers/startSubscribe`, sendData)
          .then((res) => {
            this.notifySuccess("StartSubscribe", res);
            this.dialogStart = false;
            this.getDataFromApi();
          });
      });
    },
     dialogStopConfirm() {
      let sendData = {
        id: this.stopItem.id,
        formData: JSON.parse(JSON.stringify(this.stopForm)),
      };
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        if (sendData.formData.start_date)
          sendData.formData.start_date = moment(
            sendData.formData.start_date
          ).format("YYYY-MM-DD");

        if (sendData.formData.end_date)
          sendData.formData.end_date = moment(
            sendData.formData.end_date
          ).format("YYYY-MM-DD");

        this.$store
          .dispatch(`customers/stopSubscribe`, sendData)
          .then((res) => {
            this.notifySuccess("StopSubscribe", res);
            this.dialogStop = false;
            this.getDataFromApi();
          });
      });
    },
    stop(item) {
      this.$validator.reset();
      this.stopForm = {
        start_date: null,
        end_date: null,
      };
      this.stopItem = item;
      this.dialogStop = true;
    },
     start(item) {
      this.$validator.reset();
      this.startForm = {
        date: null,
      };
      this.startItem = item;
      this.dialogStart = true;
    },
    goToDetails(id) {
      this.$router.push({name:'customerSubscriptionDetails',params:{id:id}})
    },
    clearFilters() {
      for (let key in this.filters) {
        this.filters[key] = null;
      }
      this.getDataFromApi();
    },
    getDataFromApi(options) {
      this.table.loading = true;
      this.getFrontEndData(
        `${this.config.crudApi}/getSubscriptionsData`,
        options,
        this.filters
      )
        .then((res) => {
          this.table.items = res.resources;
          this.table.length = Math.ceil(
            res.pagination.total / res.pagination.per_page
          );
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    changePage(page) {
      let options = {
        page: page,
      };
      this.getDataFromApi(options);
    },
  },
  mounted() {
    this.getDataFromApi();
  },
};
</script>